<template>
  <section class="home">
    <div class="hero">
      <div class="container">
        <div class="hero-content">
          <h1 class="slidingVertical">We Brew <br>Thoughtfully<br> Designed 
            <span class="highlight keyword-1">Apps</span>
            <span class="highlight keyword-2">Websites</span>
            <span class="highlight keyword-3">Content</span>
            <span class="highlight keyword-4">Ads</span>
            <span class="highlight keyword-5">Strategy</span>
          </h1>
          <a @click="scrollToMenu" class="hero-anchor">See Our Full Service Menu</a>
        </div>
        <picture>
          <source srcset="../assets/images/home/hero-lg.svg" media="(min-width: 700px)">
          <img src="../assets/images/home/hero-sm.svg" alt="services doodles">
        </picture>
      </div>
    </div>
    <div class="services" id="menu-section">
      <div class="container">
        <div class="services-content">
          <div class="menu-container">
            <img alt="logo" src="../assets/images/global/logo-stacked.svg" class="menu-logo">
            <h2 class="menu-title">Full Service Menu</h2>
            <div class="menu-content">
              <div class="menu-item">
                <h3>App Development</h3>
                <p>We work with all mobile and web platforms and seamlessly integrate any new technologies into your current business model. We help you with requirements analysis, custom software solutions, architecture, and UI/UX design.</p>
              </div>
              <div class="menu-item">
                <h3>Design &amp; Production</h3>
                <p>UI/UX design + various styles of graphic design. Illustration, photography, animation and video production. Our services are tailored to your needs and brand strategy.</p>
              </div>
              <div class="menu-item">
                <h3>Web Development</h3>
                <p>We build beautiful and precise digital experiences. Having an online presence helps any business access opportunities for growth. Based on your individual needs, we can help you to build a modern web solution while working with your vision and timeline and never compromising on quality.</p>
              </div>
              <div class="menu-item">
                <h3>digital marketing &amp; advertising</h3>
                <p>We increase visibility, conversions, and revenue for your business. We customize strategies exclusively based on your needs and goals and work with you every step of the way; from idea/conception, design, launch/execution, and performance analysis. We are committed to helping your business succeed.</p>
              </div>
              <div class="menu-item">
                <h3>QA &amp; Maintenace</h3>
                <p>We provide QA and testing, maintenance, system administration, and support for your existing product or post-development.</p>
              </div>
              <div class="menu-item">
                <h3>business strategy</h3>
                <p>Depending on what stage your business is in, we provide business strategy consulting, brand strategy, and business development services.</p>
              </div>
            </div>
          </div>
          <div class="dotted-border-top"></div>
          <div class="dotted-border-bottom"></div>
          <div class="dotted-border-left"></div>
          <div class="dotted-border-right"></div>
          <div class="dashed-border-top"></div>
          <div class="dashed-border-bottom"></div>
          <div class="dashed-border-left"></div>
          <div class="dashed-border-right"></div>
        </div>
      </div>
    </div>
    <div class="cta-section">
      <router-link to="/contact" class="contact-btn">Get In Touch</router-link>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    scrollToMenu() {
      document.getElementById('menu-section').scrollIntoView()
    },
  }
}
</script>
